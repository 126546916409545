<template>
  <div class="status_wrapper">
    <v-row>
      <v-col cols="12" sm="3" md="3">
        <b-card
          bg-variant="dark"
          v-bind:header="$t('Pending Signature')"
          class="text-center"
        >
          <b-card-text>{{
            statistics_data.current_pending_signature_lines
          }}</b-card-text>
        </b-card>
      </v-col>
      <v-col cols="12" sm="3" md="3">
        <b-card
          bg-variant="dark"
          v-bind:header="$t('Incidence')"
          class="text-center"
        >
          <b-card-text>{{
            statistics_data.current_incidence_lines
          }}</b-card-text>
        </b-card>
      </v-col>
      <v-col cols="12" sm="3" md="3">
        <b-card
          bg-variant="dark"
          v-bind:header="$t('Current month')"
          class="text-center"
        >
          <b-card-text>{{
            this.currency_format(statistics_data.current_total_amount)
          }}</b-card-text>
        </b-card>
      </v-col>
      <v-col cols="12" sm="3" md="3">
        <b-card
          bg-variant="dark"
          v-bind:header="$t('last 6 months')"
          class="text-center"
        >
          <b-card-text>{{
            this.currency_format(statistics_data.avg_total_amount_last6M)
          }}</b-card-text>
        </b-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "statistics",
  props: ["data"],
  data() {
    return {
      statistics_data: {
        current_pending_signature_lines: 0,
        current_incidence_lines: 0,
        current_total_amount: 0,
        avg_total_amount_last6M: 0,
      },
    };
  },
  watch: {
    async data(newVal, oldVal) {
      console.log(newVal, oldVal);
      if (newVal) {
        this.statistics_data = newVal;
      }
      return newVal;
    },
  },
  computed: {},
  methods: {
    setdata(data) {
      this.statistics_data = data;
    },
    init() {},
  },
  mounted() {
    this.init();
  },
};
</script>
<style>
.status_wrapper {
  text-align: center;
}
.status_wrapper .row {
  margin-top: 0;
  color: #000;
}
.status_wrapper .bg-dark {
  background-color: #9f9f9f !important;
}
</style>
